<template>
  <div class="Coupons" v-cloak> 
    <div class="coupon" v-for='item in discount' @click='aa(item)'>
        <div class="money" ><span>￥</span>{{item.coupon.discount_amount}}</div>
        <div class="condition">
            <span style="margin-bottom:.13rem">满{{item.coupon.meet_amount}}减{{item.coupon.discount_amount}}</span> 
             </br>
         <span>{{item.valid_end_time}}</span>  
        </div>
        <div class="click" >点击领取优惠券</div>
   </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import request from "../../plugin/request";
export default {
  data() {
    return {
      discount:'',
      course_id:'',
      use_coupon:1,
      JointReport_id:''
    };
  },
  mounted(){
  let currentQuery = this.$route.query;
  this.course_id = currentQuery.id;
  this.JointReport_id=currentQuery.JointReport_id
  this.setToken()
  this.$nextTick(() => {
      this.getlist()
  });
  },
  methods:{
    setToken(token) {
       var tokens = localStorage.getItem('token');
       console.log(tokens)
       if(tokens!=null) {
          return Cookies.set('Token', tokens)
       } else {
           return Cookies.set('Token', token)
       }
    },
    async getlist() {
      console.log(this.course_id.split(','))
      if(this.JointReport_id=='') {
        const data = await request.post('/app/coupon/acquired',{ 
        course_id:this.course_id
        }) 
        this.discount=data.data
        console.log(data.data)
              if(data.message=='您未登录或账户被禁用') {
        this.$router.push({ path:'login?url=Payment&id='+this.course_id})
      }
      } else  {
        const data = await request.post('/app/reduction/couponList',{
           id:this.JointReport_id,
           course_ids:this.course_id.split(',')
        })
        console.log(this.course_id.split(','))
         this.discount=data.data
         console.log(data.data)
        if(data.message=='您未登录或账户被禁用') {
        this.$router.push({ path:'login?url=Payment&id='+this.course_id})
      }
      }
 
    },
    aa(item) {
      this.$router.push({ path:'Payment?yhq_id='+item.id+'&id='+this.course_id+'&content='+item.coupon.meet_amount+'减'+item.coupon.discount_amount+'&code='+item.code+'&coupon_id='+item.coupon_id+'&use_coupon='+this.use_coupon})
    } 
  }
};
</script>
<style lang="less" scoped>
.Coupons {
  width:100%;
  margin-top:.47rem;
  
.coupon  {
  width: 9.41rem;
  height: 1.6rem;
  background: url(../../assets/yhq.png);
  background-size: 100% 100% ;
  margin-left: .33rem;
  margin-top:.25rem;
}

.money  {
  width: 2.71rem;
  height: 100%;
  color: #fff;
  line-height:  1.6rem;
  font-size: 0.7rem;
  padding-left: 0.37rem;
  box-sizing: border-box;
  float: left;
}

.money span {
  font-size: 0.48rem;
}

.condition  {
  width: 3.39rem;
  height: 100%;
  float: left;
  font-size: 0.32rem;
  box-sizing: border-box;
  padding-top:.32rem;
  color: #fff;

}

.condition  p  {
  padding:0;
  margin:0
}

.click  {
  height: 0.8rem;
  float: left;
  font-size: 0.35rem;
  color: #fff;
  line-height: 0.8rem;
  border-left: 0.01rem #fff  dashed;
  margin-top:.37rem;
  box-sizing: border-box;
  padding-left:0.24rem ;

}
}
</style>